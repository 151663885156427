@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.dashboard .select-field {
  top: 8px;
  left: 10px;
  font-weight: 700;
  font-size: 1.1rem;
}
.dashboard .type-selector {
  margin: 10px 10px 0px 10px;
}
.dashboard .data-value {
  font-weight: 700;
  font-size: 1.6rem;
}
.dashboard .graph-card {
  margin-bottom: 4px;
  max-width: 400px;
  width: 100%;
}
.dashboard .graph-card:last-child {
  margin-bottom: 0;
}
.dashboard .graph-card {
  max-width: 2000px;
}
.dashboard .type-tile {
  width: 100%;
}
.dashboard .type-tile .grid-tile__value {
  display: flex;
  justify-content: center;
  width: 100%;
}
.dashboard .type-tile .visu-type-button {
  flex: 1;
  background-color: #fff;
  text-transform: uppercase;
}
.dashboard .type-tile .visu-type-button:first-child {
  margin-right: 8px;
}
.grid-tile__label {
  margin-bottom: 16px;
}
/*# sourceMappingURL=src/oapps/temperature_3LuP0zVvY/components/dashboard.css.map */